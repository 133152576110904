import { convertToExportedView } from '@estee/elc-service';
import { BASIC_TOUT } from '@estee/elc-service-view-names';
import { IBasicToutProps } from './BasicTout';
import serviceNames from '~setup/ContentService';

/*eslint no-restricted-syntax: ["off"]*/
export default convertToExportedView<IBasicToutProps>(
    () => import('./BasicTout'),
    BASIC_TOUT,
    serviceNames.basicToutViewController
);
