import { getModuleFederatedName, setupServiceInfo } from '@estee/elc-universal-utils';
import { ServiceManager } from '@estee/elc-service';
import {
    CONTENT_BLOCK,
    BASIC_TOUT,
    VTO_FOUNDATION_OVERLAY,
    VTO_FOUNDATION_QR_CODE_OVERLAY,
    VIDEO_TOUT,
    VIDEO_TOUT_YOUTUBE,
    THIRD_PARTY_TOUT
} from '@estee/elc-service-view-names';
import serviceNames, { ContentService } from '~setup/ContentService';
import { serviceBusBaseClass } from '~setup/ServiceBus';

import './exported/basic-tout';
import './exported/content-block';
import './exported/third-party-tout';
import './exported/video-tout';
import './exported/video-tout-youtube';
import './exported/vto-foundation-overlay';
import './exported/vto-foundation-qr-code-overlay';

setupServiceInfo(__serviceInfo__);
const { name, version } = __serviceInfo__;

ContentService.setViews(name, getModuleFederatedName(name), version, [
    CONTENT_BLOCK,
    BASIC_TOUT,
    VTO_FOUNDATION_OVERLAY,
    VTO_FOUNDATION_QR_CODE_OVERLAY,
    VIDEO_TOUT,
    VIDEO_TOUT_YOUTUBE,
    THIRD_PARTY_TOUT
]);

ServiceManager.setServiceStartParams({
    serviceName: name,
    diContainerImport: () => import('~setup/diContainer'),
    serviceKey: serviceNames.contentService,
    serviceBusControllerKey: serviceNames.serviceBusController,
    serviceBus: {
        serviceBusBaseClass
    }
});
