import { convertToExportedView } from '@estee/elc-service';
import { VIDEO_TOUT_YOUTUBE } from '@estee/elc-service-view-names';
import { IVideoToutYoutubeProps } from './VideoToutYoutube';
import serviceNames from '~setup/ContentService';

/*eslint no-restricted-syntax: ["off"]*/
export default convertToExportedView<IVideoToutYoutubeProps>(
    () => import('./VideoToutYoutube'),
    VIDEO_TOUT_YOUTUBE,
    serviceNames.videoToutYoutubeViewController
);
