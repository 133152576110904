import { ServiceManager, ServiceBusController, ServiceBusControllerBody } from '@estee/elc-service';
import { events, on, queryProvider, serviceQueries } from '@estee/elc-service-bus';

const queries = [[serviceQueries.TEMPLATES_CONTENT_GET, 'getServices']];

export class ServiceBus extends ServiceBusController {
    @on(events.ECOMM_STARTED, { replay: true })
    public onEcommStarted = () => {
        const id = setInterval(() => {
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            if (serviceBusBaseClass) {
                clearInterval(id);
                queries.forEach(ServiceManager.setDecoratorOnFunction(queryProvider));
            }
        }, 50);
    };
}

// @ts-ignore
export const serviceBusBaseClass = new ServiceBus() as ServiceBusController &
    ServiceBusControllerBody;
