import { convertToExportedView } from '@estee/elc-service';
import { THIRD_PARTY_TOUT } from '@estee/elc-service-view-names';
import { IThirdPartyToutProps } from './ThirdPartyTout';
import serviceNames from '~setup/ContentService';

/*eslint no-restricted-syntax: ["off"]*/
export default convertToExportedView<IThirdPartyToutProps>(
    () => import('./ThirdPartyTout'),
    THIRD_PARTY_TOUT,
    serviceNames.thirdPartyToutViewController
);
