import { convertToExportedView } from '@estee/elc-service';
import { VIDEO_TOUT } from '@estee/elc-service-view-names';
import { IVideoToutProps } from './VideoTout';
import serviceNames from '~setup/ContentService';

/*eslint no-restricted-syntax: ["off"]*/
export default convertToExportedView<IVideoToutProps>(
    () => import('./VideoTout'),
    VIDEO_TOUT,
    serviceNames.videoToutViewController
);
