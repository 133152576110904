import { convertToExportedView } from '@estee/elc-service';
import { VTO_FOUNDATION_QR_CODE_OVERLAY } from '@estee/elc-service-view-names';
import { IVtoFoundationQrCodeOverlayProps } from './VtoFoundationQrCodeOverlay';
import serviceNames from '~setup/ContentService';

/*eslint no-restricted-syntax: ["off"]*/
export default convertToExportedView<IVtoFoundationQrCodeOverlayProps>(
    () => import('./VtoFoundationQrCodeOverlay'),
    VTO_FOUNDATION_QR_CODE_OVERLAY,
    serviceNames.vtoFoundationQrCodeOverlayViewController
);
