import { convertToExportedView } from '@estee/elc-service';
import { CONTENT_BLOCK } from '@estee/elc-service-view-names';
import { IContentBlock } from './ContentBlock';
import serviceNames from '~setup/ContentService';

/*eslint no-restricted-syntax: ["off"]*/
export default convertToExportedView<IContentBlock>(
    () => import('./ContentBlock'),
    CONTENT_BLOCK,
    serviceNames.contentBlockViewController
);
