import { convertToExportedView } from '@estee/elc-service';
import { VTO_FOUNDATION_OVERLAY } from '@estee/elc-service-view-names';
import { IVtoFoundationOverlayProps } from './VtoFoundationOverlay';
import serviceNames from '~setup/ContentService';

/*eslint no-restricted-syntax: ["off"]*/
export default convertToExportedView<IVtoFoundationOverlayProps>(
    () => import('./VtoFoundationOverlay'),
    VTO_FOUNDATION_OVERLAY,
    serviceNames.vtoFoundationOverlayViewController
);
